<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "manage-reception-desks"
            )
          }}
        </div>
        <template v-slot:right>
          <router-link
            :to="{
              name: 'r_add-reception'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName(
                'visitor-management',
                'reception-desks',
                'search'
              )
            "
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>

    <ul class="clebex-item-section pill" v-if="parents && parents.length">
      <li class="clebex-item-section-item" :key="`none`">
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedReceptionParent(null)"
        >
          <span class="label">
            <span class="highlight">
              {{ displayLabelName("receptions", "level", "None") }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item active" v-if="!receptionParent">
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
      <li
        class="clebex-item-section-item"
        v-for="parent in listOfParents"
        :key="parent.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedReceptionParent(parent)"
        >
          <span class="label">
            <span class="highlight">
              {{ parent.name }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item active"
              v-if="receptionParent && receptionParent.id === parent.id"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "ReceptionParents",
  data() {
    return {
      searchQuery: ""
    };
  },
  created() {
    let id = 0;
    if (this.reception) {
      id = this.reception.id;
    }
    this.getParents(id);
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  computed: {
    ...mapState("reception", ["parents", "receptionParent", "reception"]),
    listOfParents() {
      const query = this.searchQuery;

      if (this.parents && this.parents.length) {
        if (query && query.length > 1) {
          return this.parents.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        }
        return this.parents;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("reception", ["setReceptionParent", "getParents"]),
    setSelectedReceptionParent(reception) {
      this.setReceptionParent(reception);
      this.$router.push({ name: this.backLinkName });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
